<template>
  <v-app>
    <fondo-custom></fondo-custom>
    <dashboard-core-app-bar v-model="expandOnHover" />
    
    <v-container class="transition">
      <v-row justify="" class="mt-sm-0 mt-md-12 pa-6 pa-md-0">
        <h1 class="text-h1 darkprimary--text">
          {{ $t("more_info") }}
        </h1>
      </v-row>
      <v-row class="px-6 px-md-0">
        <p>{{ $t("more_info_subtitle") }}</p>
      </v-row>
    
      <!-- EXPANSION PANELS ROW-->
      <v-row class="pa-0">
        <v-col cols="12" sm="7" class="pl-sm-0">
          <v-expansion-panels >
            <v-expansion-panel class="px-0 rounded-lg">
              <v-expansion-panel-header class="solid-heading rounded-lg py-8 justify-space-between">
                <h3 class="white--text font-weight-light text-h3 mb-n2">¿Qué es la farmacogenética?</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="rounded-xl">
                <v-row class="pt-8">
                  <p>La Farmacogenética se define como la disciplina que estudia las variaciones hereditarias que afectan a la respuesta individual a los fármacos. Sirve para personalizar los tratamientos con fármacos a fin de evitar interacciones de riesgo, reacciones adversas o tóxicas o ineficacia terapéutica.</p>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12" sm="5" class="pr-sm-0">
          <v-expansion-panels >
            <v-expansion-panel class="px-0 rounded-lg">
              <v-expansion-panel-header class="solid-heading rounded-lg py-8 justify-space-between">
                <h3 class="white--text font-weight-light text-h3 mb-n2">Perfil farmacogenético</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="rounded-xl">
                <v-row class="pt-8">
                  <p>La Farmacogenética se define como la disciplina que estudia las variaciones hereditarias que afectan a la respuesta individual a los fármacos. Sirve para personalizar los tratamientos con fármacos a fin de evitar interacciones de riesgo, reacciones adversas o tóxicas o ineficacia terapéutica.</p>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <!-- EXPANSION PANELS ROW-->
      <v-row class="pa-0">
        <v-col cols="12" sm="5" class="pl-sm-0">
          <v-expansion-panels >
            <v-expansion-panel class="px-0 rounded-lg">
              <v-expansion-panel-header class="solid-heading rounded-lg py-8 justify-space-between">
                <h3 class="white--text font-weight-light text-h3 mb-n2">Bases de datos genéticas</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="rounded-xl">
                <v-row class="pt-8">
                  <p>La Farmacogenética se define como la disciplina que estudia las variaciones hereditarias que afectan a la respuesta individual a los fármacos. Sirve para personalizar los tratamientos con fármacos a fin de evitar interacciones de riesgo, reacciones adversas o tóxicas o ineficacia terapéutica.</p>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12" sm="7" class="pr-sm-0">
          <v-expansion-panels >
            <v-expansion-panel class="px-0 rounded-lg">
              <v-expansion-panel-header class="solid-heading rounded-lg py-8 justify-space-between">
                <h3 class="white--text font-weight-light text-h3 mb-n2">Perfil farmacogenético</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="rounded-xl">
                <v-row class="pt-8">
                  <p>Nuestro Análisis Farmacogenético es el estudio más completo a nivel mundial hoy en día. Analizamos variantes Farmacogenéticas localizadas en los 60 genes más relevantes en la respuesta a más de 1.200 fármacos, incluyendo todos los de uso común más frecuente.Tu Perfil Farmacogenético te permite saber a ti, a tu médico y a tu farmacéutico qué medicamentos te pueden provocar reacciones tóxicas o efectos adversos y, por tanto, debes evitar. Asimismo, te indica para cuáles debes ajustar la dosis estándar a fin de que el tratamiento sea efectivo.Dentro del Perfil Farmacogenético podrás encontrar paneles específicos por categorías farmacológicas (antitrombóticos, antiinflamatorios, analgésicos, anestésicos, antidepresivos, antipsicóticos, sedantes, etc.) y por patologías.</p>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

    </v-container>
  </v-app>
</template>

<script>
import FondoCustom from "@/components/mylogy/FondoCustom.vue";

export default {
  components: {
    DashboardCoreAppBar: () => import("./components/core/AppBar"),
    FondoCustom
  },
  methods: {
    goToExternal(externalURL) {
      window.open(externalURL, "_blank");
    },
    all () {
      this.panel = [...Array(this.items).keys()].map((k, i) => i)
    },
    // Reset the panel
    none () {
      this.panel = []
    },
  },
  data: () => ({
    expandOnHover: false,
    panel: [],
    items: 5
  }),
  computed: {},
  mounted() {}
};
</script>

<style lang="scss">
.transition {
  transition: .5s !important;
}
#europharma {
  background: #1c093a;
  position: fixed;
  bottom: 0;
}
@media (max-width: 900px) {
  #europharma {
    position: sticky;
    bottom: -25%;
  }
}
a {
  text-decoration: none;
}

#panel-selector {
  .v-card {
    height: 400px;
    width: calc(33% - 33px);
    min-width: min-content;
    border: solid 2px #6f4be0;
    overflow: hidden;

    .title {
      font-size: calc(2em + 10%) !important;
    }

    .v-card__actions {
      height: 20%;

      .v-btn__content {
        color: #6f4be0 !important;font-weight: 500;
      }
    }
    .card-shape {
      position: absolute;
      background: #1c093a;
      width: 120%;
      height: 160px;
      bottom: -60px;
      //transform: rotate(-7deg);
      border-radius: 50%;
    }
  }
  .v-card * {
    word-break: normal;
  }
  .v-card:nth-child(1) {
    background: url("~@/assets/img/perfil-tarxeta.png") no-repeat bottom;
    background-position-y: 150px;
    background-size: 80%;
    transition: 0.3s;
    &:hover {
      background-position-y: 120px;
    }
  }
  .v-card:nth-child(2) {
    background: url("~@/assets/img/farmacogenetica-tarxeta.png") no-repeat
      bottom;
    background-position-y: 130px;
    background-size: 90%;
    transition: 0.3s;
    &:hover {
      background-position-y: 100px;
    }
  }
  .v-card:nth-child(3) {
    background: url("~@/assets/img/guia-tarxeta.png") no-repeat bottom;
    background-position-y: 130px;
    background-size: 100%;
    transition: 0.3s;
    &:hover {
      background-position-y: 100px;
    }
  }
}
#tile_1 {
  background: url(http://localhost:8080/img/gif_1.18fb27d3.gif);
}
</style>
